import { connector } from './connector';

export async function GET_TERMS(callback, onError) {
	try {
		let url = `/term`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function GET_TERM(term_id, callback, onError) {
	try {
		let url = `/term/${term_id}`;
		let res = await connector.get(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}

export async function POST_TERM(term, callback, onError) {
	if (term) {
		try {
			let url = `/term`;
			let res = await connector.post(`${url}`, term);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function PUT_TERM(term, callback, onError) {
	if (term) {
		try {
			let newTerm = {
				term_id: term.id,
				name: term.name,
				begin: term.begin,
				end: term.end,
				session: term.session
			};
			let url = `/term`;
			let res = await connector.put(`${url}`, newTerm);
			let { data, message } = res.data;
			let status = res.data.status;
			status === 'success' && callback && callback(data, message);
			if (status !== 'success') throw res;
			return data;
		} catch (err) {
			onError && onError(err);
			return false;
		}
	}
}

export async function MAKE_TERM_CURRENT(term_id, callback, onError) {
	try {
		let url = `/term/${term_id}/current`;
		let res = await connector.put(`${url}`);
		let { data, message } = res.data;
		let status = res.data.status;
		status === 'success' && callback && callback(data, message);
		if (status !== 'success') throw res;
		return data;
	} catch (err) {
		onError && onError(err);
		return false;
	}
}
